import React from "react";
import { styles } from "./Content.style";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

export interface FunctionQuestion {
  question: string;
  date: number;
}

interface Props {
  functionQuestion: FunctionQuestion;
  yesterdayPath?: string;
  tomorrowPath?: string;
}

const QuestionContainer = styled.div`
  margin-top: 10%;
  align-self: flex-end;
`;

const Year = styled.h1`
  color: #ac190f;
  font-weight: 100;
  @media (min-width: 320px) {
    font-size: 200%;
  }
  @media (min-width: 480px) {
    font-size: 300%;
  }
  @media (min-width: 768px) {
    font-size: 400%;
  }
`;

const Day = styled.h2`
  margin: 0;
  color: #ac190f;
  font-weight: 600;
  @media (min-width: 320px) {
    font-size: 500%;
  }
  @media (min-width: 480px) {
    font-size: 600%;
  }
  @media (min-width: 768px) {
    font-size: 700%;
  }
`;

const Month = styled.h3`
  color: #ac190f;
  font-weight: 100;
  @media (min-width: 320px) {
    font-size: 200%;
  }
  @media (min-width: 480px) {
    font-size: 300%;
  }
  @media (min-width: 768px) {
    font-size: 400%;
  }
`;

const Question = styled.p`
  margin-bottom: 0;
  font-weight: 600;
  @media (min-width: 320px) {
    font-size: 200%;
  }
  @media (min-width: 480px) {
    font-size: 300%;
  }
  @media (min-width: 768px) {
    font-size: 350%;
  }
  @media (min-width: 1024px) {
    font-size: 400%;
  }
`;

const Card = styled.div`
  border-radius: 7%;
  background-color: #f4f4f4;
  padding: 5%;
  box-shadow: 0 0 75px grey;
  border: 12px solid black;
`;

const CardContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 96%;

  @media (min-width: 1024px) {
    max-width: 60%;
  }
  @media (min-width: 1440px) {
    max-width: 40%;
  }
  @media (min-width: 2560px) {
    max-width: 35%;
  }
`;

const ButtonsContainer = styled.div`
  font-size: 3.4rem;
  display: flex;
  padding-top: 3%;
  min-width: 94%;
  @media (min-width: 480px) {
    font-size: 4.4rem;
  }
  @media (min-width: 768px) {
    font-size: 5.4rem;
    min-width: 50%;
  }
  @media (min-width: 1024px) {
    font-size: 6.4rem;
    min-width: 40%;
  }
`;

function Content({
  functionQuestion,
  yesterdayPath,
  tomorrowPath,
}: Props): JSX.Element {
  const date = new Date(functionQuestion.date);
  const month = Intl.DateTimeFormat("en", { month: "long" }).format(date);

  return functionQuestion.question === "Loading..." ? (
    <ClimbingBoxLoader size={50} />
  ) : (
    <div style={styles.container}>
      <CardContainer>
        <Card>
          <div>
            <Year>{date.getFullYear()}</Year>
            <Day>{date.getDate()}</Day>
            <Month>{month}</Month>
          </div>
          <QuestionContainer>
            <Question>{functionQuestion.question}</Question>
          </QuestionContainer>
        </Card>
      </CardContainer>
      <ButtonsContainer>
        {yesterdayPath && (
          <a href={`/${yesterdayPath}`} aria-label={"To tomorrow's question"}>
            <ImArrowLeft style={styles.leftArrow} />
          </a>
        )}
        {tomorrowPath && (
          <a
            href={`/${tomorrowPath}`}
            aria-label={"To yesterday's question"}
            style={styles.rightAnchor}
          >
            <ImArrowRight style={styles.rightArrow} />
          </a>
        )}
      </ButtonsContainer>
    </div>
  );
}

export default Content;
