import React from "react";
import "./App.css";
import { StartDate } from "./constants/start.const";
import { format } from "date-fns";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Qotd from "./features/Qotd/Pages/Qotd/Qotd";
import Policy from "./features/Policy/Policy";
import { dateToUTC } from "./constants/dates.const";
import Header from "./shared/Components/Header/Header";
import Footer from "./shared/Components/Footer/Footer";

const today = new Date();

function App() {
  const isPathless = window.location.pathname === "/";
  const date = isPathless
    ? dateToUTC(today)
    : pathToUTCString(window.location.pathname);

  if (isPathless) {
    window.history.pushState(null, "", getTodayPath());
  }

  function getTodayPath(): string {
    return format(today, "yyyy/MM/dd");
  }

  function pathToUTCString(path: string): number {
    if (path === "/") {
      return dateToUTC(StartDate);
    }

    const split = path.split("/");
    if (split.length !== 4) {
      return dateToUTC(StartDate);
    }

    const year = split[1];
    if (year.length !== 4) {
      return dateToUTC(StartDate);
    }

    if (year.length !== 4) {
      return dateToUTC(StartDate);
    }

    let month = split[2];
    if (month.length === 1) {
      month = "0" + month;
    }
    if (month.length !== 2) {
      return dateToUTC(StartDate);
    }

    let day = split[3];
    if (day.length === 1) {
      day = "0" + day;
    }
    if (day.length !== 2) {
      return dateToUTC(StartDate);
    }

    return Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/policy" element={<Policy />}></Route>
          <Route
            path="/:year/:month/:date"
            element={<Qotd date={date} today={today} />}
          ></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
