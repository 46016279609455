import { StartDate } from "./start.const";

export enum Errors {
  Unknown = "UNKNOWN",
  DateInDistantPast = "DATE_IN_DISTANT_PAST",
  DateInFuture = "DATE_IN_FUTURE",
}

export function ErrorsToFriendly(error: Errors): string {
  switch (error) {
    case Errors.DateInDistantPast:
      return `I started this on ${StartDate.toLocaleDateString()} so there isn't a question for this date`;
    case Errors.DateInFuture:
      return "That date is in the future you cheeky bugger, fuck off";
    default:
      return "An unknown error has occurred, sorry about that. Shit happens ¯\\_(ツ)_/¯";
  }
}
