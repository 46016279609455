import React, { useEffect, useState } from "react";
import { Errors } from "../../../../constants/errors.const";
import Content, { FunctionQuestion } from "../../Components/Content/Content";
import { StartDate } from "../../../../constants/start.const";
import { format } from "date-fns";
import { initializeApp } from "firebase/app";
import { FirebaseConfig } from "../../../../constants/firebase.const";
import { getFunctions, httpsCallable } from "firebase/functions";
import Error from "../../../../shared/Components/Error/Error";
import { styles } from "./Qotd.style";

const app = initializeApp(FirebaseConfig);
const functions = getFunctions(app);
const questionOfTheDay = httpsCallable(functions, "questionOfTheDay");

interface Props {
  date: number;
  today: Date;
}

function Qotd({ date, today }: Props): JSX.Element {
  today.setHours(1, 0, 0, 0);
  const [functionQuestion, setQuestion] = useState({
    question: "Loading...",
    date: date,
  });
  const [error, setError] = useState<Errors>();

  function setAndClearError(e: Errors): void {
    setError(e);
    localStorage.clear();
  }

  useEffect(() => {
    const storageKey = "QOTD" + date;
    const question = localStorage.getItem(storageKey);
    if (question) {
      const cookie: FunctionQuestion = JSON.parse(question);
      if (
        cookie.date === date &&
        cookie.question !== functionQuestion.question
      ) {
        setQuestion(cookie);
      }
      return;
    }

    questionOfTheDay({ date })
      .then((response) => {
        if (!response.data) {
          setAndClearError(Errors.Unknown);
          return;
        }

        const data = response.data as { error: Errors };

        if (data.error) {
          setAndClearError(data.error);
          return;
        }

        const functionQuestion = data as unknown as FunctionQuestion;
        localStorage.setItem(storageKey, JSON.stringify(functionQuestion));
        setQuestion(functionQuestion);
      })
      .catch((e) => {
        console.error(e);
        setAndClearError(Errors.Unknown);
      });
  }, [date, functionQuestion.question, today]);

  const hasYesterday = StartDate < new Date(date);
  function getYesterdayPath(): string {
    const yesterday = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);
    return format(yesterday, "yyyy/MM/dd");
  }

  const hasTomorrow = today > new Date(date);
  function getTomorrowPath(): string {
    const tomorrow = new Date(date);
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return format(tomorrow, "yyyy/MM/dd");
  }

  return (
    <main style={styles.container}>
      {error && <Error error={error} />}
      {!error && (
        <Content
          functionQuestion={functionQuestion}
          yesterdayPath={hasYesterday ? getYesterdayPath() : ""}
          tomorrowPath={hasTomorrow ? getTomorrowPath() : ""}
        />
      )}
    </main>
  );
}

export default Qotd;
