import React from "react";
import { styles } from "./Header.style";
import { ImQuestion } from "react-icons/im";
import styled from "styled-components";

const HomeAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  align-items: center;
  display: flex;
`;

const H1Tag = styled.h1`
  flex: 1;
  display: none;
  margin: 0;
  @media (min-width: 768px) {
    font-size: 300%;
    display: flex;
  }
`;

const Logo = styled.div`
  margin: 1%;
  margin-bottom: 0.5%;
  font-size: 300%;
  @media (min-width: 480px) {
    font-size: 200%;
  }
  @media (min-width: 768px) {
    font-size: 300%;
  }
`;

function Header(): JSX.Element {
  return (
    <header style={styles.header}>
      <nav>
        <HomeAnchor href={"https://qoftheday.co.uk"} aria-label={"Home"}>
          <Logo>
            <ImQuestion />
          </Logo>
          <H1Tag>Question of the day</H1Tag>
        </HomeAnchor>
      </nav>
    </header>
  );
}

export default Header;
