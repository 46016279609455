import React from "react";
import { Errors, ErrorsToFriendly } from "../../../constants/errors.const";

interface Props {
  error: Errors;
}

function Error({ error }: Props): JSX.Element {
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>{ErrorsToFriendly(error)}</h1>
    </div>
  );
}

export default Error;
