import React from "react";
import { styles } from "./Footer.style";
import styled from "styled-components";

const Policy = styled.a`
  @media (min-width: 768px) {
    font-size: 200%;
  }
  margin: 1%;
  margin-bottom: 0.5%;
`;

function Footer(): JSX.Element {
  return (
    <footer style={styles.footer}>
      <Policy href={"/policy"}>Privacy Policy</Policy>
    </footer>
  );
}

export default Footer;
