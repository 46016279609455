export const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    textAlign: "center" as "center",
  },
  leftArrow: {
    textDecoration: "none",
    color: "black",
    alignSelf: "flex-start",
  },
  rightAnchor: {
    marginLeft: "auto",
  },
  rightArrow: {
    textDecoration: "none",
    color: "black",
    alignSelf: "flex-end",
  },
};
