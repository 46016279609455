import React from "react";
import { styles } from "./Policy.style";

function Policy(): JSX.Element {
  return (
    <main style={styles.container}>
      <h1>Privacy policy</h1>
      <p>
        This privacy policy has been compiled to better serve those who are
        concerned with how their 'Personally identifiable information' (PII) is
        being used online. PII is information that can be used on its own or
        with other information to identify, contact, or locate a single person,
        or to identify an individual in context. Please read our privacy policy
        carefully to get a clear understanding of how we collect, use, protect
        or otherwise handle your Personally Identifiable Information on our
        website www.qoftheday.co.uk.
      </p>
      <h2>
        What personal information do we collect from the people that visit our
        website?
      </h2>
      <p>
        You may be asked to enter your email address or other details to help
        you with your experience when submitting a feedback, and, if in the
        future registration is implemented on the site, upon registration.
      </p>
      <h2>When do we collect information?</h2>
      <p>
        We collect information from you for advertising purposes when you visit
        the site. Information like age, location, and other sites you visited
        may be collected.
      </p>
      <h2>Do we use 'cookies'?</h2>
      <p>
        Yes. Cookies are small files that a site or its service provider
        transfers to your computer's hard drive through your Web browser (if you
        allow) that enable the site's or service provider's systems to recognize
        your browser and capture and remember certain information. They are also
        used to help us understand your preferences based on previous or current
        site activity, which enables us to provide you with improved services.
        We also use cookies to help us compile aggregate data about site traffic
        and site interaction so that we can offer better site experiences and
        tools in the future. We use cookies to:
        <ul>
          <li>Understand and save user's preferences for future visits.</li>
          <li>Keep track of advertisements.</li>
          <li>
            Compile aggregate data about site traffic and site interactions in
            order to offer better site experiences and tools in the future. We
            may also use trusted third-party services that track this
            information on our behalf.
          </li>
        </ul>
        You can choose to have your computer warn you each time a cookie is
        being sent, or you can choose to turn off all cookies. You do this
        through your browser settings. Each browser is a little different, so
        look at your browser's Help menu to learn the correct way to modify your
        cookies. If you disable cookies, some features may be disabled but it
        mustn't affect your user experience in any material way.
      </p>
      <h2>Third-party links</h2>
      <p>
        Occasionally, at our discretion, we may include or offer third-party
        products or services on our website. These third-party sites have
        separate and independent privacy policies. We therefore have no
        responsibility or liability for the content and activities of these
        linked sites. Nonetheless, we seek to protect the integrity of our site
        and welcome any feedback about these sites.
      </p>
      <h2>Third-party cookies</h2>
      <p>
        Third party vendors, including Google and Disqus, use cookies to serve
        ads and other content based on a user's prior visits to this website.
        Google's use of the DoubleClick cookie enables it and its partners to
        serve ads based on their visit to this sits and/or other sites on the
        Internet. You may opt out of the use of the DoubleClick cookie for
        interest-based advertising by visiting Ads Settings. You may be also
        interested to visit this page for instructions how to opt out of a
        third-party vendor's use of cookies for interest based advertising. We
        use cookies from the following third parties:
        <ul>
          <li>Google Adsense</li>
        </ul>
        <ul>
          <li>Google Fonts</li>
        </ul>
        <ul>
          <li>Google Analytics</li>
        </ul>
      </p>
      <h2>How to disable cookies</h2>
      <p>
        Most modern browsers allow you to control your cookie settings for all
        websites that you browse. You can disable cookie deployment completely
        by editing your browser settings, however in doing this you may be
        limiting the functionality that is displayed on our website. To learn
        how to disable cookies on your preferred browser we recommend reading
        this advice posted by Google. If you are concerned about cookies
        tracking your movements on the Internet then you may be concerned about
        spyware. Spyware is the name given to a particular band of cookies that
        track personal information about you. There are many anti-spyware
        programs that you can use to prevent this from happening. To learn more
        about anti-spyware software, visit
        https://en.wikipedia.org/wiki/Spyware.
      </p>
    </main>
  );
}

export default Policy;
